<template>
  <div class="c-forgotpwd">
    <div class="c-forgotpwd__body">
      <h1 class="c-forgotpwd__body-title"><span class = "c-forgotpwd__body-title-bold" >Forgot </span>your password?</h1>
      <p class="c-forgotpwd__body-description">Enter your email address to reset your password. You may need to check your</p>
      <p class="c-forgotpwd__body-description">spam folder or unblock donotreply@sparrowexchange.com.</p>
      <!-- <form id="resetPwdGeetest"> -->
      <div class = "c-forgotpwd__body-form-container">
        <div class = "c-forgotpwd__body-form-group">
          <input id="email" class="c-forgotpwd__body-form-group-input" type="email" name="email" placeholder="email address" required v-model="input.email">
        </div>
        <!-- <div class="error-text general hide"></div> -->
        <div id="captcha">
            <div id="wait" class="show">
                <div class="loading">
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                    <div class="loading-dot"></div>
                </div>
            </div>
        </div>
        <button id="geetest-element" type = "submit" class = "c-forgotpwd__body-form-submit" v-if='input.email' @click = "executeGeetest">Submit</button>
        <button type = "submit" disabled class = "c-forgotpwd__body-form-submit-disabled" v-else>Submit</button>
        <!-- <button type = "submit" class = "c-forgotpwd__body-form-submit" :class="{'c-forgotpwd__body-form-submit-disabled': !validateEmail}">Submit</button> -->
      </div>
    </div>
    <Geetest ref="geetest" @verify="authenticate"></Geetest>
    <InvalidCaptchaAlert v-if = "invalidCaptchaStatus"></InvalidCaptchaAlert>
  </div>
</template>

<script>
import InvalidCaptchaAlert from '@/components/InvalidCaptchaAlert'
import Geetest from '@/components/Geetest'
import publicKey from 'raw-loader!../../public/key/sparrowSecurityAuthPub.key'

export default {
  name: 'ForgotPwd',
  components: {
    Geetest,
    InvalidCaptchaAlert
  },
  data() {
    return {
      input:{
        email: '',
        t:Date.now()
      },
      btnStat: false,
      forge: require('node-forge'),
      processing: false,
      maintenance:false,
      publicKey:publicKey,
      pubKey:null,
      captchaRes:'',
      invalidCaptchaStatus: false,
      validEmail: false,
    }
  },
  created() {
    this.$eventHub.$on('invalidCaptchaStatus', (status) =>{
      this.invalidCaptchaStatus = status
    });
  },
  watch:{

  },
  mounted(){
    this.pubKey = this.forge.pki.publicKeyFromPem(this.publicKey);
  },
  methods:{
    checkMaintenance() {
      this.$http.get(this.createApiUrlPricing('get_platform_status')).then(response => {
        var apistatus = response.body.api_status
        var pricingstatus = response.body.pricing_status
        if(apistatus=="0" || pricingstatus=="0") {
          this.maintenance = true
          this.$router.push('/maintenance').catch(() => {})
        } else {
          this.maintenance = false
        }
      }, err => {
        console.log(err)
        // this.showMessageError(err, false , err.status)
      })
    },
    encryptInput:function(){
      this.input.t = Date.now();
      const enc = this.symEncrypt(this.input);
      const key = this.pubKey.encrypt(enc.key, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      const iv = this.pubKey.encrypt(enc.iv, 'RSA-OAEP', {
        mgf1: {
          md: this.forge.md.sha1.create(),
        },
      });
      return {
        ciphertext: this.forge.util.encode64(enc.ciphertext),
        key: this.forge.util.encode64(key),
        iv: this.forge.util.encode64(iv),
      };
    },
    symEncrypt:function(dt){
      const key = this.forge.random.getBytesSync(32);
      const iv = this.forge.random.getBytesSync(16);
      const encCipher = this.forge.aes.createEncryptionCipher(key, 'CBC');
      encCipher.start(iv);
      encCipher.update(this.forge.util.createBuffer(JSON.stringify(dt), 'utf-8'));
      encCipher.finish();
      const ciphertext = encCipher.output.data;
      return {
        key,
        iv,
        ciphertext,
      };
    },
    executeGeetest:function(){
      if(this.processing){
        return false
      }
      this.processing = true;
      this.$refs.geetest.execute()
    },
    authenticate: function(captchaRes){
      var encryptedInput = this.encryptInput();
      this.captchaRes = captchaRes
      let _this = this

      this.$http.post(this.createApiUrlChestNut("users/reset-password?geetest_challenge="+captchaRes.geetest_challenge+"&geetest_seccode="+captchaRes.geetest_seccode+"&geetest_validate="+captchaRes.geetest_validate), encryptedInput).then(() =>{
        localStorage.setItem('email', this.input.email);
        this.$router.push("/emailsent-forgotpwd").catch(() => {});
      },() => {
        _this.$refs.geetest.reset()
        this.checkMaintenance()
        if(this.maintenance) {
          return false
        } else {
          // if(err.body.code=='OTPRequired'){
          //   this.processing = false
          //   this.$router.push({ name: 'Signin Twofa', params: {
          //     email: this.input.email,
          //     password: this.input.password,
          //     captchaRes: this.captchaRes
          //   }})
          // }
          // else if(err.body.code == 'DeviceAuthRequired'){
          //   this.processing = false
          //   window.location = 'main/unrecognized.html'
          // }else if (err.body.code == 'IncorrectEmailPassword') {
          //   this.errorText = "Email / password is incorrect"
          //   this.processing = false
          // }
          // else{
          //   if(err.status == 0) {
          //     this.errorText = 'Too many requests received, please try again shortly.'
          //     this.processing = false
          //   } else {
          //     this.errorText = err.body.message;
          //     this.processing = false
          //   }
          // }
        }
      });
    },
  },
  computed:{
    // validateEmail:function(){
    //   return this.validEmail;
    // }
  }
}
</script>
